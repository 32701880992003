import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import { Layout } from 'modules/ui';
import { BlogPostsPage, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext, PostAuthor } from 'modules/blog/types';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
    enPosts: {
        nodes: BlogPost[];
    };
    csPosts: {
        nodes: BlogPost[];
    };
    dePosts: {
        nodes: BlogPost[];
    };
    strapiAuthor: PostAuthor;
}

const BlogPostsAuthor = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => (
    <>
        <BlogSeo
            title={`${data.strapiAuthor.displayName} #${pageContext.currentPage}`}
            description={data.strapiAuthor.description ?? ''}
        />
        <Layout>
            <BlogPostsPage
                author={data.strapiAuthor}
                posts={data.allStrapiPost.nodes}
                basePath={`/blog/author/${data.strapiAuthor.slug}`}
                pageContext={pageContext}
                csPosts={data.csPosts.nodes}
                enPosts={data.enPosts.nodes}
                dePosts={data.dePosts.nodes}
                displayOtherLanguages
            />
        </Layout>
    </>
);

export const pageQuery = graphql`
    query ($slug: String!, $skip: Int!, $limit: Int!, $email: String, $localeRegex: String) {
        strapiAuthor(slug: { eq: $slug }, locale: { regex: $localeRegex }) {
            ...PostAuthor
        }
        allStrapiPost(
            filter: { author: { email: { eq: $email } }, locale: { regex: $localeRegex } }
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                ...BlogPost
                locale
            }
        }
        csPosts: allStrapiPost(
            filter: { author: { email: { eq: $email } }, locale: { eq: "cs-CZ" } }
            sort: { fields: publishedAt, order: DESC }
            limit: 2
        ) {
            nodes {
                ...BlogPost
            }
        }
        enPosts: allStrapiPost(
            filter: { author: { email: { eq: $email } }, locale: { eq: "en" } }
            sort: { fields: publishedAt, order: DESC }
            limit: 2
        ) {
            nodes {
                ...BlogPost
            }
        }
        dePosts: allStrapiPost(
            filter: { author: { email: { eq: $email } }, locale: { eq: "de" } }
            sort: { fields: publishedAt, order: DESC }
            limit: 2
        ) {
            nodes {
                ...BlogPost
            }
        }
    }
`;

export default BlogPostsAuthor;
